<script setup lang="ts">
const { ourWork } = useHome();
const lp = useLocalePath();
</script>

<template>
  <Container
    v-if="ourWork"
    class="screen-content flex flex-col items-center px-0 py-16"
  >
    <div class="mb-8 flex flex-col items-center justify-center text-center">
      <Heading as="h2" size="caption" class="mb-2">{{ ourWork.title }}</Heading>

      <Heading as="h3" size="medium" class="mb-4 flex flex-col px-10 lg:px-0">
        {{ ourWork.headline }}
      </Heading>

      <Paragraph size="medium" class="mb-8 max-w-lg px-8 lg:px-0">
        {{ ourWork.body }}
      </Paragraph>

      <Action
        v-if="ourWork.action"
        as="link"
        :to="lp('/projects')"
        :intent="ourWork.action.intent"
        :size="ourWork.action.size"
      >
        {{ ourWork.action.label }}
      </Action>
    </div>

    <ProjectsSlider :projects="ourWork.projects" />
  </Container>
</template>
